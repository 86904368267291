
import { Options, Vue } from 'vue-class-component';
import RadioButton from '@/common/components/form-ui/radio-button.component.vue';
import { clientModule } from '@/modules/client/client.vuex-module';
import { OrderPayment } from '@/common/models/OrderPayment.model';
import { InjectReactive, Prop, Watch } from 'vue-property-decorator';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { SquareCard } from '@/common/interfaces/SquareCard.interface';
import RadioButtonGroup from '@/common/components/form-ui/radio-button-group.component.vue';

@Options({
	name: 'CreditCardPaymentMethod',
	components: { RadioButton, RadioButtonGroup },
	emits: ['canSubmit'],
})
export default class CreditCardPaymentMethod extends Vue {
	@InjectReactive() orderPayment!: OrderPayment;
	@InjectReactive() activeOrder!: ActiveOrder;

	selectedCard = 0; // index of selected card

	squareCard!: SquareCard; // square card
	squareConfig = {
		appId: clientModule.location?.PaymentPublicApiKey,
		locationId: clientModule.location?.SquareLocationId,
	};
	get cardOptions() {
		const options = [
			// ...this.orderPayment.CardsOnFile.map((c) => {
			// 	return {
			// 		label: `Card *${c.Last4}`,
			// 		value: c.OrderPaymentKey,
			// 	};
			// }),
			{
				label: 'New Card',
				value: 0,
			},
		];
		return options;
	}

	async created() {
		this.squareCard = await this.initSquareWebPayments()
			.then((card) => {
				return card;
			})
			.finally(() =>
				setTimeout(() => {
					this.$emit('canSubmit', true);
				}, 1000),
			);
	}

	async getSquareToken() {
		return await this.squareCard.tokenize().then((token: any) => {
			return token.token;
		});
	}

	async initSquareWebPayments() {
		const payments = (window as any).Square.payments(this.squareConfig.appId, this.squareConfig.locationId);
		const card = await payments.card({
			style: {
				// View limited Square styling selectors https://tinyurl.com/39htzet5
				input: {
					fontSize: '14px',
				},
			},
		});

		return await card.attach('#card-container').then(() => {
			return card;
		});
	}
}
